import { FC, useReducer } from 'react'
import { useSwipeable } from 'react-swipeable'
import Hotkeys from 'react-hot-keys'
import styled from 'styled-components'
import LoopCard from './LoopCard'
import { LoopDetail } from './loops'

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

const NO_CLICK_DELTA = 20

type Direction = 'Left' | 'Right'

type LoopTrackState = {
  pos: number
  delta: number
  slidingDir?: Direction
}

export const CarouselContainer = styled.div<{
  slidingDir?: Direction
  delta: number
  pos: number
}>`
  display: flex;
  align-items: center;
  transition: ${props =>
    props.slidingDir || props.delta ? 'none' : 'transform 0.6s ease'};
  transform: ${props => {
    if (!props.slidingDir)
      return `translateX(calc(${props.delta}px + 12.5% - 12px + ${props.pos} * (-75% - 24px)))`
    if (props.slidingDir === 'Left')
      return `translateX(calc(${props.delta}px + 12.5% - 12px + ${
        props.pos + 1
      } * (-75% - 24px)))`
    return `translateX(calc(${props.delta}px + 12.5% - 12px + ${
      props.pos - 1
    } * (-75% - 24px)))`
  }};
`

// Watch out!  this interacts with the sizes of the cards defined in App.css!
export const CarouselSlot = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 75%;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface LoopTrackProps {
  cardType: string
  availableLoops: LoopDetail[]
  currentLoop?: LoopDetail
  currentMute: boolean
  setLoop: (cardType: string, loop: LoopDetail) => void
  setMute: (cardType: string, mute: boolean) => void
  setSwipeMetadata: (cardType: string, loopId: string) => void
}

type LoopTrackAction =
  | { type: Direction }
  | { type: 'stopSliding' }
  | { type: 'swiping'; delta: number }

const reducer = (
  state: LoopTrackState,
  action: LoopTrackAction
): LoopTrackState => {
  switch (action.type) {
    case 'Left':
      return {
        ...state,
        slidingDir: 'Left',
      }
    case 'Right':
      return {
        ...state,
        slidingDir: 'Right',
      }
    case 'stopSliding':
      return { ...state, delta: 0, slidingDir: undefined }
    case 'swiping':
      return { ...state, delta: action.delta, slidingDir: undefined }
  }
}

const getInitialState = (): LoopTrackState => {
  return {
    pos: 0,
    delta: 0,
    slidingDir: undefined,
  }
}

const LoopTrack: FC<LoopTrackProps> = ({
  availableLoops,
  currentLoop,
  setLoop,
  currentMute,
  setMute,
  cardType,
  setSwipeMetadata,
}) => {
  const [state, dispatch] = useReducer(reducer, getInitialState())
  const pos =
    currentLoop !== undefined ? availableLoops.indexOf(currentLoop) : 0

  const shiftLeft = () => {
    if (pos === 0) {
      setTimeout(() => {
        dispatch({ type: 'stopSliding' })
      }, 2)
      return
    }
    dispatch({ type: 'Left' })
    setTimeout(() => {
      dispatch({ type: 'stopSliding' })
    }, 2)

    const newLoop = availableLoops[pos - 1]
    setLoop(cardType, newLoop)
    setSwipeMetadata(cardType, newLoop.loop_id)
  }

  const shiftRight = () => {
    if (pos == availableLoops.length - 1) {
      setTimeout(() => {
        dispatch({ type: 'stopSliding' })
      }, 2)
      return
    }
    dispatch({ type: 'Right' })
    setTimeout(() => {
      dispatch({ type: 'stopSliding' })
    }, 2)
    const newLoop = availableLoops[pos + 1]
    setLoop(cardType, newLoop)
    setSwipeMetadata(cardType, newLoop.loop_id)
  }

  const primaryPointerIsTouch =
    window?.matchMedia('(pointer: coarse)')?.matches ?? false
  const handlers = useSwipeable({
    onSwiped: eventData => {
      if (eventData.dir == 'Left') {
        shiftRight()
      }
      if (eventData.dir == 'Right') {
        shiftLeft()
      }
    },
    onSwiping: eventData => {
      dispatch({ type: 'swiping', delta: eventData.deltaX })
    },
    delta: NO_CLICK_DELTA,
    preventDefaultTouchmoveEvent: true,
    // Note - as of this writing, react-swipeable (6.2.0)
    // has bugs when both trackMouse and trackTouch are on.
    // We decide which one to track based on the primary pointer
    // type.
    trackMouse: !primaryPointerIsTouch,
    trackTouch: primaryPointerIsTouch,
  })

  const setLoopHandler = loop => {
    setLoop(cardType, loop)
    setSwipeMetadata(cardType, loop.loop_id)
  }

  return (
    <div {...handlers}>
      <Hotkeys
        keyName='left,right'
        onKeyDown={keyName => {
          if (keyName == 'left') {
            shiftLeft()
          }
          if (keyName == 'right') {
            shiftRight()
          }
        }}
        onKeyUp={() => {}}
      >
        <Wrapper>
          <CarouselContainer
            slidingDir={state.slidingDir}
            delta={state.delta}
            pos={pos}
          >
            {availableLoops.map(loop => (
              <CarouselSlot key={loop.loop_id}>
                <LoopCard
                  type={cardType}
                  inhibitClicks={
                    state.slidingDir !== undefined ||
                    Math.abs(state.delta) >= NO_CLICK_DELTA
                  }
                  currentMute={currentMute}
                  setMute={setMute}
                  active={loop == currentLoop}
                  makeActive={() => setLoopHandler(loop)}
                ></LoopCard>
              </CarouselSlot>
            ))}
          </CarouselContainer>
        </Wrapper>
      </Hotkeys>
    </div>
  )
}

export default LoopTrack
