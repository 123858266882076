import React, {
  createRef,
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react'
import { CSSTransition } from 'react-transition-group'
import Hotkeys from 'react-hot-keys'
import { PlaybackParamUpdates, useIzoAudio } from '@izocloud/audio-provider'
import { Button, FlexContainer, Image, Text } from '@izocloud/ui'
import { useAnalytics, events } from '@izocloud/analytics'
import { useAuth } from '@izocloud/auth'
import path from 'path'
import { useInterval, useUpdateEffect } from 'usehooks-ts'
import AudioVisualization from './AudioVisualization'
import { AUDIO_CDN, DOWNLOAD_API_URL, DOWNLOAD_CDN } from '../utils'
import {
  LoopDetail,
  LoopSet,
  ActiveLoops,
  LoopTypes,
  LoopType,
  VibeType,
} from './loops'
import LoadingIndicator from './LoadingIndicator'
import LoopTrack from './LoopTrack'
import SetVibe from './SetVibe'
import { ExternalLink } from '@izocloud/ui'
import { Spacer } from '@izocloud/ui'

const ZIP_ENDPOINT = DOWNLOAD_API_URL + 'firestarter-zip'
const INFO_ENDPOINT = DOWNLOAD_API_URL + 'firestarter-get-dlinfo'
const EXIT_TIMEOUT = 100

type LooperState = {
  activeLoops: ActiveLoops
  globalMute: boolean
  engineStarted: boolean
  downloadWaiting: boolean
  downloadsRemaining?: number
  refreshTime?: number
  knownLoops?: LoopSet
}

interface LooperProps {
  loops: LoopSet
  seed: string
  selectedLoops: ActiveLoops | null
  currentVibe: VibeType | null
  shouldRenderSetVibe: boolean
  setShouldRenderSetVibe: React.Dispatch<React.SetStateAction<boolean>>
  engineStarted: boolean
  setEngineStarted: React.Dispatch<React.SetStateAction<boolean>>
  resetLoops: () => void
  popupLogin: () => void
  setVibe: (vibe: VibeType) => void
  shareOpened: boolean
  setShareOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const getFilesForLoops = (loops: LoopSet) => {
  const allDetails: LoopDetail[][] = Object.values(loops)
  return allDetails.flatMap(loopDetails =>
    loopDetails.map(loop => {
      let s3uri = encodeURI(AUDIO_CDN + '/' + loop.fileName + '.mp3')
      s3uri = s3uri.replace(/#/g, '%23')
      return {
        src: s3uri,
        label: loop.loop_id,
        mute: { value: true },
      }
    })
  )
}

const changeExt = (loop: string, oldExt: string, newExt: string) => {
  if (path.extname(loop) === oldExt) {
    const basename = path.basename(loop, path.extname(loop))
    loop = path.join(path.dirname(loop), basename + newExt)
  } else if (path.extname(loop) !== newExt) {
    // Expect this case if no extension present. But path.extname(loop) might not be empty
    // if filename contains a `.` Therefore, explicitly check that extention is NOT the desired
    // new extension
    loop = loop + newExt
  }
  return loop
}

const numSwipesInitialState = LoopTypes.reduce((acc, val) => {
  return { ...acc, [val]: 0 }
}, {})

const Looper: FC<LooperProps> = ({
  loops,
  seed,
  selectedLoops,
  resetLoops,
  popupLogin,
  setVibe,
  currentVibe,
  shouldRenderSetVibe,
  setShouldRenderSetVibe,
  engineStarted,
  setEngineStarted,
  shareOpened,
  setShareOpened,
}) => {
  const audio = useIzoAudio()
  const { user, getCreds } = useAuth()
  const { mixpanelEvent } = useAnalytics()
  const { setPlayback, loadFiles, state, setPlaybackParams } = audio
  const { files, playing, filesLoading, filesLoaded } = state
  const [numSwipes, setNumSwipes] = useState(numSwipesInitialState)
  const [copyLinkClicked, setCopyLinkClicked] = useState<boolean>(false)
  const [accessToken, setAccessToken] = useState<string>()
  const [infoRetries, setInfoRetries] = useState<number>(0)
  const wantToDownload = useRef(false)

  useUpdateEffect(() => {
    if (mixpanelEvent) mixpanelEvent(events.signInCompleted)
  }, [user, mixpanelEvent])

  useEffect(() => {
    if (user && wantToDownload.current) {
      download()
    }
  }, [user])

  useEffect(() => {
    if (!shareOpened) {
      wantToDownload.current = false
    }
  }, [shareOpened])

  useEffect(() => {
    if (mixpanelEvent) {
      if (selectedLoops !== null) {
        mixpanelEvent(events.openSharedProject, { loopIds: selectedLoops })
      } else {
        mixpanelEvent(events.getInspiredPageLoad)
      }
    }
  }, [mixpanelEvent])

  const [looperState, setLooperState] = useState<LooperState>({
    activeLoops: {},
    globalMute: false,
    engineStarted: false,
    downloadWaiting: false,
  })

  const mixpanelSwipe = useCallback(
    (inst: LoopType, loopId) => {
      mixpanelEvent(events.swipe, {
        track: inst,
        numSwipes: numSwipes[inst] + 1,
        loopId,
      })
      setNumSwipes({ ...numSwipes, [inst]: numSwipes[inst] + 1 })
    },
    [numSwipes]
  )

  const setLoop = (inst: LoopType, loop?: LoopDetail) => {
    setLooperState(currentState => {
      const newState = {
        ...currentState,
        globalMute: false,
        activeLoops: {
          ...currentState.activeLoops,
          [inst]: { loop, muted: false },
        },
      }
      if (loop === undefined) {
        delete newState.activeLoops[inst]
      }
      if (currentState.globalMute) {
        for (const loop of Object.keys(newState.activeLoops)) {
          if (loop !== inst) newState.activeLoops[loop].muted = true
        }
      }
      return newState
    })
  }

  const addTrack = useCallback(() => {
    for (const label of LoopTypes) {
      if (!looperState.activeLoops[label as LoopType]) {
        setLoop(label as LoopType, loops[label as LoopType][0])
        if (mixpanelEvent)
          mixpanelEvent(events.addTrack, { loopId: loops[label][0].loop_id })
        return
      }
    }
  }, [looperState.activeLoops, loops, mixpanelEvent])

  const downloadClickHandler = useCallback(async () => {
    if (mixpanelEvent) mixpanelEvent(events.downloadButtonClick)
    if (!user) {
      wantToDownload.current = true
      await popupLogin()
    } else {
      download()
    }
  }, [
    user,
    looperState.activeLoops,
    looperState.downloadsRemaining,
    mixpanelEvent,
  ])

  const download = useCallback(async () => {
    if (looperState.downloadsRemaining === 0) return
    const { accessToken } = await getCreds()
    setAccessToken(accessToken)
    const allIds = Object.values(looperState.activeLoops).flatMap(
      activeLoop => activeLoop.loop.loop_id
    )
    if (mixpanelEvent)
      mixpanelEvent(events.download, {
        loopIds: allIds,
        downloadsRemaining: looperState.downloadsRemaining,
      })
    const allKeys = Object.values(looperState.activeLoops).flatMap(
      activeLoop =>
        'loops/' + changeExt(activeLoop.loop.fileName, '.mp3', '.wav')
    )

    allKeys.push('readmes/🔥 Made with Trackstarter 🔥.txt')
    const musicalKey = looperState.activeLoops.melodic.loop.key
    const tempo = looperState.activeLoops.melodic.loop.tempo
    const payload = {
      outFile: `Trackstarter-${musicalKey}-${tempo}bpm.zip`,
      keys: allKeys,
    }
    setLooperState(currentState => ({
      ...currentState,
      downloadWaiting: true,
    }))
    const response = await fetch(ZIP_ENDPOINT, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(payload), // body data type must match "Content-Type" header
    })
    const resp = await response.json()
    if (response.status === 200 && resp.download_url) {
      const element = document.createElement('a')
      element.setAttribute('href', DOWNLOAD_CDN + resp.download_url)
      element.setAttribute('download', 'download')
      document.body.appendChild(element)
      element.click()
    } else if (response.status !== 429) {
      console.error('bad response from download API', resp)
    }
    setLooperState(currentState => ({
      ...currentState,
      downloadWaiting: false,
      downloadsRemaining:
        resp.downloads_remaining ?? currentState.downloadsRemaining,
      refreshTime: resp.refresh ?? currentState.refreshTime,
    }))
    wantToDownload.current = false
  }, [looperState.activeLoops, looperState.downloadsRemaining, mixpanelEvent])

  const share = useCallback(async () => {
    if (mixpanelEvent) mixpanelEvent(events.copyLinkButtonClick)
    const SITE_URL = window.location.protocol + '//' + window.location.host
    const loops = LoopTypes.map(t => {
      if (looperState.activeLoops[t]) {
        return [
          t[0],
          looperState.activeLoops[t].loop.loop_id,
          looperState.activeLoops[t].muted ? '1' : '0',
        ].join('|')
      }
    })
    if (typeof window !== 'undefined') {
      try {
        let link =
          SITE_URL +
          '/share?loops=' +
          window.btoa(loops.join('///')) +
          '&seed=' +
          seed
        if (currentVibe) {
          link += '&vibe=' + currentVibe
        }
        await window.navigator.clipboard.writeText(link)
        setCopyLinkClicked(true)
      } catch (e) {
        console.error(e)
      }
    }
  }, [looperState.activeLoops, loops, mixpanelEvent])

  useEffect(() => {
    if (!playing && filesLoaded) {
      setPlayback(true)
    }

    if (
      playing &&
      filesLoaded &&
      Object.keys(looperState.activeLoops).length === 0
    ) {
      setTimeout(() => addTrack(), EXIT_TIMEOUT)
    }
  }, [
    filesLoaded,
    filesLoading,
    playing,
    setPlayback,
    addTrack,
    looperState.activeLoops,
  ])

  useEffect(() => {
    if (looperState.knownLoops === undefined) {
      setLooperState(currentState => ({
        ...currentState,
        knownLoops: loops,
      }))
      return
    }

    if (looperState.knownLoops !== loops) {
      if (engineStarted) {
        load()
        setLooperState(currentState => ({
          ...currentState,
          knownLoops: loops,
          activeLoops: {},
        }))
      }
    }
  }, [looperState.knownLoops, loops, engineStarted])

  useEffect(() => {
    const playbackParams: PlaybackParamUpdates = {}
    for (const file of files) {
      playbackParams[file.label] = {
        mute: {
          value: looperState.globalMute
            ? true
            : !Object.values(looperState.activeLoops)
                .flatMap(activeLoop =>
                  activeLoop.muted ? [] : [activeLoop.loop.loop_id]
                )
                .includes(file.label),
        },
      }
    }
    setPlaybackParams(playbackParams)
  }, [looperState]) // eslint-disable-line

  const callInfoEndpoint = async () => {
    const { accessToken } = await getCreds()
    setAccessToken(accessToken)
    const response = await fetch(INFO_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    const resp = await response.json()
    if (
      (response.status !== 200 && response.status !== 429) ||
      !resp.refresh ||
      resp.downloads_remaining === undefined
    ) {
      console.error('bad response from get info API', resp)
    } else {
      setLooperState(currentState => ({
        ...currentState,
        downloadsRemaining: resp.downloads_remaining,
        refreshTime: resp.refresh,
      }))
    }
  }

  useEffect(() => {
    if (filesLoaded && selectedLoops !== null) {
      setLooperState(currentState => {
        return {
          ...currentState,
          globalMute: false,
          activeLoops: selectedLoops,
        }
      })
      setEngineStarted(true)
    }
  }, [filesLoaded, selectedLoops])

  const load = useCallback(() => {
    setPlayback(false)
    const filesToLoad = getFilesForLoops(loops)
    ;(async () => {
      await loadFiles(filesToLoad)
      const allDetails: LoopDetail[][] = Object.values(loops)
      const flatmap = allDetails.flatMap(loopDetails =>
        loopDetails.map(loop => loop.loop_id)
      )
      if (mixpanelEvent) mixpanelEvent(events.filesLoaded, { loopIds: flatmap })
    })()
  }, [loops, mixpanelEvent])

  const setMute = useCallback(
    (inst: LoopType, muted: boolean) => {
      const thisInst = looperState.activeLoops[inst]
      if (!thisInst) {
        return
      }

      let globalMute = !muted ? false : looperState.globalMute
      let newLooperState = {
        ...looperState,
        globalMute,
        activeLoops: {
          ...looperState.activeLoops,
          [inst]: { ...thisInst, muted },
        },
      }

      if (!muted && looperState.globalMute) {
        for (const loop of Object.keys(newLooperState.activeLoops)) {
          if (loop !== inst) newLooperState.activeLoops[loop].muted = true
        }
      }

      if (!globalMute && muted) {
        let allMuted = true
        for (const loop of Object.values(newLooperState.activeLoops)) {
          if (!loop.muted) allMuted = false
        }
        if (allMuted) {
          newLooperState.globalMute = true
          for (const loop of Object.keys(newLooperState.activeLoops)) {
            newLooperState.activeLoops[loop].muted = false
          }
        }
      }

      setLooperState(newLooperState)

      if (mixpanelEvent)
        mixpanelEvent(events.muteTrack, { track: inst, mute: muted })
    },
    [looperState, mixpanelEvent]
  )

  const setGlobalMute = useCallback(
    (globalMute: boolean) => {
      setLooperState({
        ...looperState,
        globalMute,
      })
      if (mixpanelEvent) mixpanelEvent(events.globalMute, { mute: globalMute })
    },
    [looperState, mixpanelEvent]
  )

  const tracks = LoopTypes.map(label => {
    const itemRef = createRef<HTMLDivElement>()
    const trackLabel = label as LoopType
    const currentlyMuted = looperState.activeLoops[trackLabel]?.muted

    return (
      <div key={label}>
        <CSSTransition
          nodeRef={itemRef}
          key={label}
          timeout={{ enter: 200, exit: EXIT_TIMEOUT }}
          classNames='loop-track-transition'
          in={looperState.activeLoops[trackLabel] !== undefined}
          appear
          unmountOnExit
        >
          <div ref={itemRef} className='card-track-wrapper'>
            <LoopTrack
              cardType={trackLabel}
              setLoop={setLoop}
              currentMute={
                looperState.globalMute
                  ? true
                  : currentlyMuted === undefined
                  ? true
                  : currentlyMuted
              }
              setMute={setMute}
              currentLoop={looperState.activeLoops[trackLabel]?.loop}
              availableLoops={loops[trackLabel]}
              setSwipeMetadata={mixpanelSwipe}
            />
          </div>
        </CSSTransition>
      </div>
    )
  })

  function downloadText() {
    if (looperState.downloadsRemaining === undefined) return 'Download loops'
    if (looperState.downloadsRemaining > 0) {
      return (
        <FlexContainer direction='vertical' secondaryAlign='center'>
          Download loops
          <Text id='Download-smalltext' type='small' color='white'>
            {looperState.downloadsRemaining} left today
          </Text>
        </FlexContainer>
      )
    } else {
      const now = Math.floor(new Date().getTime() / 1000)
      const hours = Math.max(
        Math.floor((looperState.refreshTime - now) / (60 * 60)) % 24,
        0
      )
        .toString()
        .padStart(2, '0')
      const mins = Math.max(
        Math.floor((looperState.refreshTime - now) / 60) % 60,
        0
      )
        .toString()
        .padStart(2, '0')
      const secs = Math.max((looperState.refreshTime - now) % 60, 0)
        .toString()
        .padStart(2, '0')
      return (
        <FlexContainer direction='vertical' secondaryAlign='center'>
          Get more downloads
          <Text id='Download-smalltext' type='small' color='white'>
            in {hours}:{mins}:{secs}
          </Text>
        </FlexContainer>
      )
    }
  }

  useInterval(
    () => {
      const now = Math.floor(new Date().getTime() / 1000)
      if (
        looperState.refreshTime === undefined ||
        (looperState.refreshTime && looperState.refreshTime + 1 <= now)
      ) {
        ;(async () => {
          await callInfoEndpoint()
          setInfoRetries(0)
        })().catch(e => {
          console.error('error when trying to call info endpoint', e)
          setInfoRetries(infoRetries + 1)
        })
      }
    },
    !user || infoRetries > 2 ? null : 1000
  )

  const tracksAllFull =
    Object.keys(looperState.activeLoops).length === LoopTypes.length

  const resetHandler = () => {
    if (mixpanelEvent)
      mixpanelEvent(events.resetButtonClick, {
        numTracks: Object.keys(looperState.activeLoops).length,
      })
    if (selectedLoops !== null) {
      window.location.href =
        window.location.protocol + '//' + window.location.host
    } else {
      resetLoops()
      setShareOpened(false)
      setNumSwipes(numSwipesInitialState)
    }
  }

  const shareHandler = (open: boolean) => {
    setShareOpened(open)
    if (mixpanelEvent && open) mixpanelEvent(events.shareButtonClick)
    if (mixpanelEvent && !open) mixpanelEvent(events.closeShareMenu)
  }

  const startPreloadedHandler = () => {
    if (mixpanelEvent) mixpanelEvent(events.playSharedProject)
    load()
  }

  const footer = (
    <div className={`looper-footer ${user ? 'authed' : ''}`}>
      {filesLoaded && !shareOpened ? (
        <Button
          onClick={() => setGlobalMute(!looperState.globalMute)}
          className={'global-mute-button'}
        >
          <Image
            src={looperState.globalMute ? 'ic_muted.svg' : 'ic_mute.svg'}
            alt='mute/unmute'
          />
        </Button>
      ) : null}

      <div className='utility-container'>
        {!engineStarted && selectedLoops !== null ? (
          <Button
            onClick={startPreloadedHandler}
            className='start-preloaded-engine-button'
          >
            <Image src='ic_play.svg' alt='play' />
          </Button>
        ) : null}
        {engineStarted && (looperState.downloadWaiting || filesLoading) ? (
          <LoadingIndicator />
        ) : null}
        {engineStarted &&
        !looperState.downloadWaiting &&
        !filesLoading &&
        !shareOpened &&
        tracksAllFull ? (
          <Button
            className='download-button'
            onClick={() => shareHandler(true)}
          >
            <Image src='ic_download.svg' alt='share' />
          </Button>
        ) : null}
        {engineStarted &&
        !looperState.downloadWaiting &&
        !filesLoading &&
        !tracksAllFull ? (
          <Button onClick={addTrack} className='add-track-button'>
            <Image src='ic_add_loop.svg' alt='add loop' />
          </Button>
        ) : null}
        {shareOpened && !looperState.downloadWaiting ? (
          <Button
            onClick={() => {
              shareHandler(false)
              setCopyLinkClicked(false)
            }}
            className='close-share-button'
          >
            <Image src='ic_close.svg' alt='close' />
          </Button>
        ) : null}
      </div>

      {filesLoaded && !shareOpened ? (
        <Button className='reset-button' onClick={() => resetHandler()}>
          <Image src='ic_reset.svg' alt='reset' />
        </Button>
      ) : null}
    </div>
  )

  if (shouldRenderSetVibe) {
    return (
      <div id='SetVibeContainer'>
        <AudioVisualization />
        <Hotkeys
          keyName='esc'
          onKeyDown={(_, event) => {
            event.preventDefault()
            setShouldRenderSetVibe(false)
          }}
        >
          <div className='looper-track-container'>
            <div className='intro-text'>
              <h1>Set a vibe</h1>
              <p>{'Get new sounds every time'}</p>
            </div>
            <SetVibe
              setVibe={setVibe}
              setEngineStarted={() => setEngineStarted(true)}
              currentVibe={currentVibe}
            />
          </div>
        </Hotkeys>
      </div>
    )
  }

  return (
    <Hotkeys
      keyName='up,down,space,enter,esc'
      onKeyDown={(keyName, event) => {
        event.preventDefault()
        if (!engineStarted) {
          if (['enter', 'space'].includes(keyName)) {
            setEngineStarted(true)
            load()
          }
          return
        }
        if (keyName == 'enter') {
          if (!tracksAllFull) {
            addTrack()
          }
        }
        if (keyName == 'space') {
          setGlobalMute(!looperState.globalMute)
        }
        if (keyName == 'esc') {
          shareOpened ? setShareOpened(false) : resetHandler()
        }
      }}
    >
      <div className='looper-container'>
        <AudioVisualization />
        <div className='looper-track-container'>
          {!engineStarted && selectedLoops === null ? (
            <>
              <div className='intro-text'>
                <h1>Welcome</h1>
                <p>{'Daily inspiration awaits'}</p>
              </div>
              <SetVibe
                setVibe={setVibe}
                setEngineStarted={() => setEngineStarted(true)}
              />
            </>
          ) : null}
          {!engineStarted && selectedLoops !== null ? (
            <div className='intro-text'>
              <h1>Welcome</h1>
              <p>{'Press play to listen'}</p>
            </div>
          ) : null}
          {!shareOpened ? (
            tracks
          ) : (
            <div>
              <div className='looper-share-wrapper'>
                <div className='looper-share-container'>
                  <div className='looper-share-slot'>
                    <Button type='text' size='large' onClick={share}>
                      <FlexContainer
                        secondaryAlign='center'
                        className='card card-active'
                      >
                        {!copyLinkClicked ? 'Copy link' : 'Link copied!'}
                      </FlexContainer>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='looper-share-wrapper'>
                <div className='looper-share-container'>
                  <div className='looper-share-slot'>
                    <Button
                      type='text'
                      size='large'
                      onClick={downloadClickHandler}
                    >
                      <FlexContainer
                        direction='vertical'
                        secondaryAlign='center'
                        className={
                          looperState.downloadsRemaining === undefined ||
                          looperState.downloadsRemaining > 0
                            ? 'card card-active'
                            : 'card card-mute disabled'
                        }
                      >
                        {downloadText()}
                        {!user ? (
                          <Text
                            id='Download-smalltext'
                            type='small'
                            color='white'
                          >
                            Sign-in required
                          </Text>
                        ) : null}
                      </FlexContainer>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!engineStarted && selectedLoops === null ? null : footer}
        {!user ? (
          <FlexContainer
            direction='horizontal'
            primaryAlign='end'
            id='TOS-container'
          >
            <Button type='text' size='small'>
              <ExternalLink to='https://www.izotope.com/en/privacy-policy.html'>
                Privacy
              </ExternalLink>
            </Button>
            <Spacer width={8} />
            <Button type='text' size='small'>
              <ExternalLink to='https://www.izotope.com/en/terms-of-use.html'>
                Terms
              </ExternalLink>
            </Button>
          </FlexContainer>
        ) : null}
      </div>
    </Hotkeys>
  )
}

export default Looper
