export const AUTH0_CONFIG: {
  domain: string
  clientId: string
  redirectUri: string
  audience: string
  useRefreshTokens: boolean
} = {
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '',
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '',
  redirectUri: typeof window !== 'undefined' ? window.location?.origin : '',
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || '',
  useRefreshTokens: true,
}

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

export const AUDIO_CDN = process.env.NEXT_PUBLIC_AUDIO_CDN || ''
export const AUDIO_CDN_WAV = process.env.NEXT_PUBLIC_AUDIO_CDN_WAV || ''

export const EMAILOPTIN_URL = process.env.NEXT_PUBLIC_EMAILOPTIN_URL || ''
export const LOCAL_STORAGE_KEY_EMAIL_OPTIN = 'izocloud_emailOptIn'
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN
export const HOTJAR_TRACKING_ID = process.env.NEXT_PUBLIC_HOTJAR_TRACKING_ID
  ? parseInt(process.env.NEXT_PUBLIC_HOTJAR_TRACKING_ID)
  : undefined

export const DOWNLOAD_API_URL = process.env.NEXT_PUBLIC_DOWNLOAD_API_URL
export const DOWNLOAD_CDN = process.env.NEXT_PUBLIC_DOWNLOAD_CDN

export const LocalStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined

// Match values also affect share links
// potential breaking case: share created with 3 melodic match req, but visited with app requiring 5
export const MELODIC_MATCH_MINIMUM = 3
export const BASS_MATCH_MINIMUM = 1
export const PERCUSSION_MATCH_MINIMUM = 2
export const DRUMS_MATCH_MINIMUM = 2
