import { FC } from 'react'
import { Header, Footer, Login, FlexContainer } from '@izocloud/ui'
import HeaderLeft from './HeaderLeft'

export const AuthCustomLogin: FC = () => {
  return (
    <FlexContainer id='AuthCustomLogin' direction='vertical'>
      <Header left={<HeaderLeft />} />
      <Login
        redirectLoginOptions={{
          appState: {
            returnTo: window !== undefined ? window.location?.href : '/',
          },
        }}
      />
      <Footer />
    </FlexContainer>
  )
}
