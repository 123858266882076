import { FC, useRef, useEffect } from 'react'

declare global {
  var globalAnalyser: AnalyserNode // eslint-disable-line no-var
}

// Get the device pixel ratio, falling back to 1.
const dpr = () => window.devicePixelRatio || 1

function resizeCanvasToDisplaySize(canvas: HTMLCanvasElement) {
  // look up the size the canvas is being displayed
  const width = canvas.clientWidth * dpr()
  const height = canvas.clientHeight * dpr()

  // If it's resolution does not match change it
  if (canvas.width !== width || canvas.height !== height) {
    canvas.width = width
    canvas.height = height
    return true
  }

  return false
}

const barWidth = () => 8 * dpr()
const barGap = () => 4 * dpr()

const minLogFreq = Math.log(85)
const maxLogFreq = Math.log(15000)
const nyquist = 24000

const binsToBars = (bins: Uint8Array, width: number): Array<number> => {
  const numBars = ~~(width / (barWidth() + barGap()))
  const ret = Array(numBars)

  const xToFreq = (x: number) => {
    return Math.exp((x / width) * (maxLogFreq - minLogFreq) + minLogFreq)
  }

  const xToBin = (x: number) => {
    const freq = xToFreq(x)
    return ~~((freq / nyquist) * bins.length)
  }
  for (let i = 0; i < numBars; i++) {
    const lowBin = xToBin((barWidth() + barGap()) * i)
    const highBin = Math.min(
      xToBin((barWidth() + barGap()) * i + barWidth()),
      bins.length
    )
    if (lowBin > highBin) {
      ret[i] = 0
    } else {
      let sum = 0
      for (let j = lowBin; j <= highBin; j++) {
        sum += bins[j]
      }
      sum = sum / (highBin - lowBin + 1)
      const k = 0.7
      const freqScaled =
        Math.exp((((barWidth() + barGap()) * i) / width) * k) * sum
      const scaled = Math.min(1, freqScaled / 255)
      ret[i] = Math.pow(scaled, 3)
    }
  }
  return ret
}

const AudioVisualization: FC = () => {
  const analyserCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const requestIdRef = useRef<number | null>(null)
  // const data = new Float32Array(1024)

  useEffect(() => {
    const data = new Uint8Array(1024)

    if (!analyserCanvasRef.current) {
      console.log('no ref.current')
      return
    }

    const renderFrame = (dataParam: any) => {
      if (!analyserCanvasRef.current) {
        console.log('no ref.current')
        return
      }
      const ctx = analyserCanvasRef.current.getContext('2d')
      if (!ctx) {
        console.log('no ctx')
        return
      }
      resizeCanvasToDisplaySize(ctx.canvas)
      if (
        analyserCanvasRef.current !== null &&
        analyserCanvasRef.current.height !== null
      ) {
        const height = analyserCanvasRef.current?.height || 0 // super hacky to avoid typescript linting
        const width = analyserCanvasRef.current?.width || 0 // super hacky to avoid typescript linting
        const scale = height

        ctx.fillStyle = 'white'
        ctx.lineWidth = barWidth()
        ctx.strokeStyle = 'rgba(255,255,255,0.2)'
        const bars = binsToBars(dataParam, width)
        const adjustedBarGap =
          (width - barWidth() * bars.length) / (bars.length - 1)
        ctx.clearRect(0, 0, width, height)
        bars.forEach((value: number, i: number) => {
          ctx.beginPath()
          const x = (adjustedBarGap + barWidth()) * i
          ctx.moveTo(x, 0)
          ctx.lineTo(x, value * scale)
          ctx.stroke()
        })
      } else {
        console.log(`no analyserCanvasRef or canvas height`)
      }
    }

    const tick = () => {
      if (!analyserCanvasRef.current) {
        console.log('no ref.current')
        return
      }
      if (typeof window.globalAnalyser !== 'undefined') {
        window.globalAnalyser.getByteFrequencyData(data)
        // window.globalAnalyser.getByteTimeDomainData(data)
        // window.globalAnalyser.getFloatFrequencyData(data)
        renderFrame(data)
      }
      requestIdRef.current = requestAnimationFrame(tick)
    }

    // if (analyserCanvasRef.current !== null && typeof window.globalAnalyser !== 'undefined') {
    requestAnimationFrame(tick)
    // TODO get cleanup working
    return () => {
      if (requestIdRef.current) {
        cancelAnimationFrame(requestIdRef.current)
      }
    }
  }, [])

  return (
    <>
      <canvas
        width='900'
        height='50'
        ref={analyserCanvasRef}
        className='visualizer'
      ></canvas>
    </>
  )
}

export default AudioVisualization
