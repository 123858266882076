import { FC } from 'react'

const LoadingIndicator: FC = () => {
  return (
    <div className='loading-indicator'>
      <div className='la-ball-spin-clockwise'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default LoadingIndicator
