import { useCallback, useEffect, useRef, useState } from 'react'
import { AudioProvider } from '@izocloud/audio-provider'
import {
  EmailOptIn,
  Header,
  LOCAL_STORAGE_KEY,
  FlexContainer,
  Button,
} from '@izocloud/ui'
import { useAnalytics, events } from '@izocloud/analytics'
import { isInternalUser } from '@izocloud/utils/functions'
import { useAuth } from '@izocloud/auth'
import { v4 as uuid } from 'uuid'
import seedrandom from 'seedrandom'
import Dropdown from './Dropdown'
import HeaderCenter from './HeaderCenter'
import {
  BASS_MATCH_MINIMUM,
  DRUMS_MATCH_MINIMUM,
  EMAILOPTIN_URL,
  LocalStorage,
  MELODIC_MATCH_MINIMUM,
  PERCUSSION_MATCH_MINIMUM,
} from '../utils'
import Looper from './Looper'
import HeaderLeft from './HeaderLeft'
import {
  getLoops,
  getLoopById,
  LoopTypes,
  LoopType,
  LoopSet,
  GetLoopsOptions,
  ActiveLoop,
  ActiveLoops,
  LoopMatchConfig,
  IncludeAllVibesConfig,
  VibeType,
} from './loops'
import useSessionStorage from './useSessionStorage'

// seedrandom.PRNG is this weird object that can also be called as a function
// e.g. you can do rng() to get a float, or rng.int32() to get an integer.
// Passing around a seedrandom.PRNG as a React state variable causes issues
// where react will always try to call the function when you don't want it to.
// So we encapsulate the PRNG in an interface to prevent that from happening.
interface rand {
  rng: seedrandom.PRNG
}

const getLoopSet = (
  rng: seedrandom.PRNG,
  selected?: ActiveLoops,
  options?: GetLoopsOptions
): LoopSet | null => {
  let l: LoopSet | null = null
  if (selected) {
    l = getLoops(rng, {
      key: selected.melodic.loop.key,
      tempo: selected.melodic.loop.tempo,
      ...options,
    })
  } else l = getLoops(rng, options)
  return l
}

const getNewSeed = (): string => {
  const seed = uuid().substring(0, 8)
  return seed
}

const getRandomGradient = (rng: seedrandom.PRNG): string => {
  const getRandomColorChannelValue = () => Math.round(rng() * 0.7 * 255)
  const getRandomColor = () =>
    `rgb(${getRandomColorChannelValue()}, ${getRandomColorChannelValue()}, ${getRandomColorChannelValue()})`
  return `linear-gradient(${getRandomColor()}, ${getRandomColor()})`
}

function App() {
  const { user, loginWithPopup } = useAuth()
  const { mixpanelEvent, mixpanel, hotjarEvent } = useAnalytics()
  const params = useRef<URLSearchParams>(
    typeof window !== 'undefined'
      ? new URLSearchParams(window?.location?.search)
      : null
  )
  const [seed, setSeed] = useState<string>()
  const [rand, setRand] = useState<rand | undefined>(() => {
    if (seed) return { rng: seedrandom(seed) }
    else return undefined
  })
  const [loops, setLoops] = useState<LoopSet>()
  const [gradient, setGradient] = useState<string>()
  const [lastGradient, setLastGradient] = useState<string | undefined>(
    undefined
  )
  const [lastGradientFade, setLastGradientFade] = useState<boolean>(false)
  const lastGradientFadeClass = lastGradientFade ? 'last-gradient-fade' : ''
  const [redirectToUpload, setRedirectToUpload] = useState(false)
  const [selectedLoops, setSelectedLoops] = useState<ActiveLoops | null>(null)
  const [shouldRenderSetVibe, setShouldRenderSetVibe] = useState(false)
  const [shareOpened, setShareOpened] = useState<boolean>(false)
  const [engineStarted, setEngineStarted] = useState(false)
  const vibe = useRef<VibeType | null>()
  const [matchConfig, setMatchConfig] = useSessionStorage<LoopMatchConfig>(
    'matchConfig',
    {
      melodic: MELODIC_MATCH_MINIMUM,
      bass: BASS_MATCH_MINIMUM,
      percussion: PERCUSSION_MATCH_MINIMUM,
      drums: DRUMS_MATCH_MINIMUM,
    }
  )
  const [includeAllVibes, setIncludeAllVibes] =
    useSessionStorage<IncludeAllVibesConfig>('includeAllVibes', {
      melodic: false,
      bass: false,
      percussion: true,
      drums: false,
    })
  const popup = useRef<Window>()
  const shouldRenderOptIn = useRef(false)

  const setInSessionStorage = (k: string, v: any) => {
    if (typeof window == 'undefined') {
      console.warn(
        `Tried setting sessionStorage key ${k} even though environment is not a client`
      )
    }

    try {
      window.sessionStorage.setItem(k, JSON.stringify(v))
    } catch (error) {
      console.warn(`Error setting sessionStorage key ${k}:`, error)
    }
  }

  useEffect(() => {
    setInSessionStorage('matchConfig', matchConfig)
  }, [matchConfig])

  useEffect(() => {
    setInSessionStorage('includeAllVibes', includeAllVibes)
  }, [includeAllVibes])

  useEffect(() => {
    if (user) {
      shouldRenderOptIn.current = true
    }
  }, [user])

  useEffect(() => {
    if (mixpanel && user && user.email) {
      mixpanel.people.set_once('is_internal', isInternalUser(user.email))
      mixpanel.identify(user.sub)
    }
  }, [mixpanel, user])

  useEffect(() => {
    const passedSeed = params.current.get('seed')
    if (passedSeed) {
      setSeed(passedSeed)
      if (params.current.get('vibe')) {
        vibe.current = params.current.get('vibe') as VibeType
      }
    } else {
      const newSeed = getNewSeed()
      setSeed(newSeed)
    }
  }, [])

  useEffect(() => {
    if (shouldRenderSetVibe) setShareOpened(false)
  }, [shouldRenderSetVibe])

  useEffect(() => {
    const passedLoops = params.current.get('loops')
    let active: ActiveLoops | null = null

    if (passedLoops) {
      const activeLoops = window.atob(passedLoops).split('///')
      if (activeLoops.length > 1) {
        const keyMap: Record<string, LoopType> = {
          m: 'melodic',
          d: 'drums',
          b: 'bass',
          p: 'percussion',
        }
        active = {
          bass: undefined,
          melodic: undefined,
          drums: undefined,
          percussion: undefined,
        }
        for (const l of activeLoops) {
          // string-encoded as "type|id|muted"
          //   type: 'm' | 'b' | 'p' | 'd'
          //   id: '0123456789abcdef'
          //   muted: '0' | '1'
          // example: "m|67fd312c0c25f5a5|0"
          const loopData = l.split('|')
          if (
            loopData.length !== 3 ||
            !Object.keys(keyMap).includes(loopData[0]) ||
            !['0', '1'].includes(loopData[2])
          ) {
            // format of share link is bad
            console.error('Bad loop data in share link', l)
            active = null
            break
          }
          const [loopShortType, loopId, loopMuted] = loopData
          const newLoop = getLoopById(loopId)
          if (!newLoop) {
            console.error('missing loop id (not in manifest)', loopId)
            active = null
            break
          }
          const a: ActiveLoop = {
            loop: newLoop,
            muted: loopMuted === '1',
          }
          active[newLoop.type.toLowerCase()] = a
        }
      }
    }
    if (active !== null) {
      setSelectedLoops(active)
    }
  }, [])

  useEffect(() => {
    if (!loops || !selectedLoops) return

    for (const t of LoopTypes) {
      if (!selectedLoops[t]) continue
      const sLoop = selectedLoops[t].loop
      const existingLoops = loops[t].filter(l => l.loop_id === sLoop.loop_id)
      if (existingLoops.length === 0) {
        setLoops(currentLoops => ({
          ...currentLoops,
          [t]: [sLoop, ...currentLoops[t]],
        }))
      }
    }
  }, [loops, selectedLoops])

  useEffect(() => {
    if (seed) {
      const r = seedrandom(seed)
      setRand({ rng: r })
      setGradient(getRandomGradient(r))
    }
  }, [seed])

  useEffect(() => {
    if (rand) {
      let l: LoopSet
      if (selectedLoops) {
        l = getLoopSet(rand.rng, selectedLoops, {
          matchConfig,
          includeAllVibes,
          vibe: vibe.current,
        })
      } else {
        l = getLoopSet(rand.rng, undefined, {
          matchConfig,
          includeAllVibes,
          vibe: vibe.current,
        })
      }
      if (l) {
        setLoops(l)
      } else {
        if (selectedLoops) {
          console.error(
            'Bad seed passed in conjunction with selectedLoops. This should never happen!'
          )
          throw new Error('bad seed on share link')
        }
        setSeed(getNewSeed())
      }
    }
  }, [rand, selectedLoops, matchConfig, includeAllVibes])

  const popupLogin = async () => {
    if (popup.current) {
      popup.current.close()
    }
    const newPopup = window.open('', '', 'width=400,height=650')
    popup.current = newPopup
    await loginWithPopup({ display: 'popup' }, { popup: newPopup })
  }

  const signInHandler = useCallback(() => {
    popupLogin()
    if (mixpanelEvent) mixpanelEvent(events.signInButtonClick)
  }, [mixpanelEvent])

  if (loops === undefined || rand === undefined) {
    return <></>
  }

  const setVibeHandler = (v?: VibeType) => {
    if (v) {
      vibe.current = v
    } else {
      vibe.current = null
    }
    setSeed(getNewSeed())
    setShouldRenderSetVibe(false)
  }

  function Content() {
    const emailOptinVal =
      typeof LocalStorage === 'undefined'
        ? null
        : LocalStorage?.getItem(LOCAL_STORAGE_KEY)
    if (
      !shouldRenderOptIn.current ||
      engineStarted ||
      (emailOptinVal ?? redirectToUpload)
    ) {
      return (
        <AudioProvider loop={true}>
          <div id='root'>
            <div
              className={`new-gradient ${lastGradientFadeClass}`}
              style={{ background: gradient }}
            ></div>
            <div
              className={`old-gradient ${lastGradientFadeClass}`}
              style={{
                background: lastGradient ? lastGradient : 'transparent',
              }}
            ></div>
            <div className='App'>
              <Looper
                loops={loops}
                seed={seed}
                selectedLoops={selectedLoops}
                resetLoops={() => {
                  setLastGradient(gradient)
                  setLastGradientFade(true)
                  setTimeout(() => {
                    setLastGradientFade(false)
                  }, 20)
                  setSeed(getNewSeed())
                }}
                popupLogin={popupLogin}
                setVibe={setVibeHandler}
                shouldRenderSetVibe={shouldRenderSetVibe}
                setShouldRenderSetVibe={setShouldRenderSetVibe}
                engineStarted={engineStarted}
                setEngineStarted={setEngineStarted}
                currentVibe={vibe.current}
                shareOpened={shareOpened}
                setShareOpened={setShareOpened}
              ></Looper>
            </div>
          </div>
        </AudioProvider>
      )
    } else {
      return (
        <EmailOptIn redirect={setRedirectToUpload} optInUrl={EMAILOPTIN_URL} />
      )
    }
  }

  const signIn = (
    <Button type='text' onClick={signInHandler}>
      Sign in
    </Button>
  )

  return (
    <FlexContainer
      direction='vertical'
      primaryAlign='space-between'
      id='App-container'
    >
      <Header
        left={engineStarted ? <HeaderLeft /> : null}
        right={user ? <Dropdown confirm={engineStarted} /> : signIn}
        center={
          <HeaderCenter
            engineStarted={engineStarted}
            shouldRenderSetVibe={shouldRenderSetVibe}
            setShouldRenderSetVibe={setShouldRenderSetVibe}
            musicalKey={loops.melodic[0].key}
            tempo={loops.melodic[0].tempo}
            vibe={vibe.current}
            isSharedPage={selectedLoops ? true : false}
          />
        }
      />
      {Content()}
    </FlexContainer>
  )
}

export default App
