import React, { createContext, useContext, useState, useEffect } from 'react';
import { UAParser } from 'ua-parser-js';
import { v4 } from 'uuid';
import * as Integrations from '@sentry/integrations';
import { Integrations as Integrations$1 } from '@sentry/tracing';
import * as Sentry$1 from '@sentry/react';

const APP_VERSION = process.env.REACT_APP_VERSION || process.env.NEXT_PUBLIC_VERSION || '';
if (typeof window !== 'undefined')
    window.izo_app_version = APP_VERSION;
let Mixpanel;
let GoogleAnalytics;
let Sentry;
let Hotjar;
const defaultValue = {};
const AnalyticsContext = createContext(defaultValue);
AnalyticsContext.displayName = 'Analytics';
const parser = new UAParser();
const userAgent = parser.getUA();
const device = parser.getDevice();
const useAnalytics = () => useContext(AnalyticsContext);
const Analytics = ({ children, MIXPANEL_TOKEN, GOOGLE_ANALYTICS_TRACKING_ID, SENTRY_DSN, SENTRY_ENVIRONMENT, HOTJAR_TRACKING_ID, ErrorBoundary, defaultMetadata = {}, }) => {
    const [providerValue, setProviderValue] = useState({});
    const [sessionId, setSessionId] = useState();
    useEffect(() => {
        let id = sessionStorage.getItem('izocloud_sessionId');
        if (id) {
            setSessionId(id);
        }
        else {
            id = v4();
            sessionStorage.setItem('izocloud_sessionId', id);
            setSessionId(id);
        }
    }, []);
    // handle mixpanel initialization on first render
    useEffect(() => {
        var _a, _b;
        // Do not load mixpanel if OneTrust is in use and consent banner has not been accepted
        const otConsentSdk = document.getElementById('onetrust-consent-sdk');
        const consentCookie = (_b = (_a = document.cookie
            .split(';')) === null || _a === void 0 ? void 0 : _a.map(i => i.trim())) === null || _b === void 0 ? void 0 : _b.filter(i => i.startsWith('OptanonAlertBoxClosed='));
        if (otConsentSdk && !consentCookie)
            return;
        if (MIXPANEL_TOKEN && sessionId) {
            Mixpanel = require('mixpanel-browser');
            Mixpanel.init(MIXPANEL_TOKEN);
            setProviderValue((currValue) => (Object.assign(Object.assign({}, currValue), { mixpanelEvent: (eventName, customMetadata = {}) => {
                    if (eventName) {
                        const md = Object.assign(Object.assign(Object.assign({}, defaultMetadata), customMetadata), { userAgent,
                            device,
                            sessionId });
                        Mixpanel.track(eventName, md);
                    }
                }, mixpanel: Mixpanel })));
        }
    }, [MIXPANEL_TOKEN, sessionId]);
    // handle google analytics initialization on first render
    useEffect(() => {
        if (GOOGLE_ANALYTICS_TRACKING_ID) {
            GoogleAnalytics = require('react-ga');
            GoogleAnalytics.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
            GoogleAnalytics.set(defaultMetadata);
            setProviderValue((currValue) => (Object.assign(Object.assign({}, currValue), { googleAnalyticsEvent: (eventName, eventDetails) => {
                    if (eventName) {
                        GoogleAnalytics.event({
                            category: 'User Event',
                            action: eventName,
                            label: eventDetails,
                        });
                    }
                } })));
        }
    }, [GOOGLE_ANALYTICS_TRACKING_ID]); // eslint-disable-line
    useEffect(() => {
        //we can console.error anywhere to fire sentry events given a dsn and env
        if (SENTRY_DSN && !SENTRY_ENVIRONMENT) {
            console.error('Sentry environment prop is required when providing SENTRY_DSN in the <Analytics /> component');
            return;
        }
        if (SENTRY_DSN) {
            Sentry = require('@sentry/react');
            Sentry.init({
                dsn: SENTRY_DSN,
                environment: SENTRY_ENVIRONMENT,
                release: APP_VERSION,
                integrations: [
                    new Integrations.CaptureConsole({
                        levels: ['error'],
                    }),
                    new Integrations.ExtraErrorData({
                        depth: 15,
                    }),
                ],
                tracesSampleRate: 1.0,
            });
        }
    }, [SENTRY_DSN, SENTRY_ENVIRONMENT]);
    // handle hotjar initialization on first render
    useEffect(() => {
        if (HOTJAR_TRACKING_ID) {
            const hjsv = 6 // current tracking code version
            ;
            ({ hotjar: Hotjar } = require('react-hotjar'));
            Hotjar.initialize(HOTJAR_TRACKING_ID, hjsv);
            setProviderValue((currValue) => (Object.assign(Object.assign({}, currValue), { hotjarEvent: (eventName) => {
                    if (eventName) {
                        Hotjar.event(eventName);
                    }
                }, hotjar: Hotjar })));
        }
    }, [HOTJAR_TRACKING_ID]); // eslint-disable-line
    const withAnalyticsProvider = (children) => (React.createElement(AnalyticsContext.Provider, { value: providerValue }, children));
    const withErrorBoundary = (children) => ErrorBoundary ? (React.createElement(Sentry.ErrorBoundary, { fallback: React.createElement(ErrorBoundary, null) }, children)) : (React.createElement(React.Fragment, null, children));
    return withErrorBoundary(withAnalyticsProvider(children));
};

const SentryWrapper = ({ children, SENTRY_DSN, }) => {
    useEffect(() => {
        Sentry$1.init({
            dsn: SENTRY_DSN,
            integrations: [new Integrations$1.BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }, [SENTRY_DSN]);
    return React.createElement(React.Fragment, null, children);
};

const events = {
    // Mastering Lab / Repair Lab: from https://docs.google.com/document/d/1wAmeE-ZTtuXiE8QSnSqq5zxGX27_twId4_9yG5Z9Qaw/
    uploadPageLoad: 'Upload Page Load',
    fileUpload: 'File Upload',
    fileProcessingSuccess: 'File Processing Success',
    fileProcessingError: 'File Processing Error',
    playAudio: 'Play Audio',
    pauseAudio: 'Pause Audio',
    toggleSource: 'Toggle Source',
    adjustmentsButtonClick: 'Adjustments Button Click',
    feedbackSubmitted: 'Feedback Submitted',
    confirmRestartPageLoad: 'Confirm Restart Page Load',
    comingSoonButtonClick: 'Coming Soon Button Click',
    newMasterButtonClick: 'New Master Button Click',
    startOverButtonClick: 'Start Over Button Click',
    sessionResumed: 'Session Resumed',
    // Firestarter
    getInspiredPageLoad: 'Get Inspired Page Load',
    addTrack: 'Add Track',
    resetButtonClick: 'Reset Button Click',
    swipe: 'Swipe',
    globalMute: 'Global Mute',
    muteTrack: 'Mute Track',
    shareButtonClick: 'Share Button Click',
    copyLinkButtonClick: 'Copy Link Button Click',
    closeShareMenu: 'Close Share Menu',
    openSharedProject: 'Open Shared Project',
    playSharedProject: 'Play Shared Project',
    filesLoaded: 'Files Loaded',
    signInButtonClick: 'Sign In Button Click (header)',
    signInCompleted: 'Sign In Completed',
    download: 'Download',
    // Shared across all apps
    loginPageLoad: 'Login Page Load',
    loginButtonClick: 'Login Button Click',
    crashPageLoad: 'Crash Page Load',
    downloadButtonClick: 'Download Button Click',
    optInPageLoad: 'Email Opt-in Page Load',
    optIn: 'Opt-In',
    continueWithoutOptIn: 'Continue Without Opt-in',
    logoutButtonClick: 'Logout Button Click',
    setVibe: 'Set Vibe',
    changeVibe: 'Change Vibe',
};

export { Analytics, SentryWrapper, events, useAnalytics };
