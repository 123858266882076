import seedrandom from 'seedrandom'
import loopManifest from './loop-manifest.json'
import packToVibe from './pack_to_vibe.json'
import {
  MELODIC_MATCH_MINIMUM,
  BASS_MATCH_MINIMUM,
  PERCUSSION_MATCH_MINIMUM,
  DRUMS_MATCH_MINIMUM,
} from '../utils/constants'

export type ActiveLoop = {
  loop: LoopDetail
  muted: boolean
}

export type ActiveLoops = {
  bass?: ActiveLoop
  melodic?: ActiveLoop
  drums?: ActiveLoop
  percussion?: ActiveLoop
}

export interface LoopSet {
  bass: LoopDetail[]
  drums: LoopDetail[]
  melodic: LoopDetail[]
  percussion: LoopDetail[]
}

export interface LoopDetail {
  loop_id: string
  fileName: string
  type: LoopType
  tempo: number
  key: string | null
  pack: string
  genres: Array<string>
  kit: string
}

export type LoopMatchConfig = Record<LoopType, number>

export type IncludeAllVibesConfig = Record<LoopType, boolean>

export interface GetLoopsOptions {
  key?: string
  tempo?: number
  vibe?: VibeType | null
  includeAllVibes?: IncludeAllVibesConfig
  matchConfig?: LoopMatchConfig
}

// This is a list of available loop types. The order is important! Changing
// it will change the order in which the tracks appear in the UI
export const LoopTypes = ['melodic', 'drums', 'bass', 'percussion'] as const
// TypeScript string union type for convenience. Derived from LoopTypes
export type LoopType = typeof LoopTypes[number]

// Ditto, for vibes
export const VibeTypes = ['electric', 'warm', 'gritty'] as const
export type VibeType = typeof VibeTypes[number]

const tempos: Set<number> = new Set<number>()
const musicalKeys: Set<string> = new Set<string>()
const loops: Array<LoopDetail> = new Array<LoopDetail>()

for (const r of Object.values(loopManifest)) {
  if (!isLoopType(r.type)) {
    console.error(r, 'type is not a LoopType')
    continue
  }
  if (!isLoopDetail(r)) {
    console.error(r, 'is not a LoopDetail')
    continue
  }
  tempos.add(r.tempo)
  if (r.key) musicalKeys.add(r.key)
  loops.push(r)
}

// Adapted from https://stackoverflow.com/a/2450976
const shuffle = <T>(toShuffle: Array<T>, rng: seedrandom.PRNG): Array<T> => {
  const ret = toShuffle.slice()
  let currentIndex = ret.length

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    const randomIndex = Math.floor(rng() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[ret[currentIndex], ret[randomIndex]] = [
      ret[randomIndex],
      ret[currentIndex],
    ]
  }

  return ret
}

export function isLoopDetail(o: any): o is LoopDetail {
  return (
    o.loop_id !== undefined &&
    o.fileName !== undefined &&
    o.type !== undefined &&
    isLoopType(o.type) &&
    o.tempo !== undefined &&
    o.key !== undefined &&
    o.key !== '' &&
    o.pack !== undefined &&
    o.genres !== undefined &&
    o.genres.length !== undefined &&
    o.kit !== undefined
  )
}

export function isLoopType(o: string): o is LoopType {
  return LoopTypes.includes(o as LoopType)
}

const matchingVibe = (l: LoopDetail, v?: VibeType): boolean => {
  return (
    // no vibe given -> automatic match
    v === undefined ||
    v === null ||
    // otherwise, check the vibe to see if it matches
    (packToVibe[l.pack] && packToVibe[l.pack] === v)
  )
}

export function getLoops(
  rng: seedrandom.PRNG,
  options?: GetLoopsOptions
): LoopSet | null {
  // we have to call this outside the `if (!key)` statement,
  // so that the rng is called a consistent number of times
  const keyRng = rng()
  let key = options?.key
  if (!key) {
    const tmpKeys: Array<string> = Array.from(musicalKeys)
    key = tmpKeys[Math.floor(keyRng * tmpKeys.length)]
  }
  const melodies = loops.filter(l => {
    if (
      l.type == 'melodic' &&
      l.key == key &&
      (options?.includeAllVibes?.melodic || matchingVibe(l, options?.vibe))
    ) {
      if (!options?.tempo) return true
      return l.tempo == options?.tempo
    } else {
      return false
    }
  })

  let matchingMelodies = new Array<LoopDetail>()
  let iter = 0
  const minimumMelodies = options?.matchConfig?.melodic || MELODIC_MATCH_MINIMUM
  while (matchingMelodies.length < minimumMelodies && iter < 10) {
    const randMelody = melodies[Math.floor(rng() * melodies.length)]
    matchingMelodies = melodies.filter(l => {
      // if we didn't filter on tempo above, now filter based on the random
      // chosen melody's tempo
      return l.tempo == randMelody.tempo
    })
    iter++
  }
  if (matchingMelodies.length < minimumMelodies) {
    return null
  }
  if (matchingMelodies.length > 8) {
    // yikes too many
    matchingMelodies = getRandomSubarray(matchingMelodies, 8, rng)
  }

  let matchingBass = new Array<LoopDetail>()
  iter = 0
  const minimumBass = options?.matchConfig?.bass || BASS_MATCH_MINIMUM
  while (matchingBass.length < minimumBass && iter < 10) {
    matchingBass = loops.filter(l => {
      return (
        l.type == 'bass' &&
        l.key == key &&
        l.tempo == matchingMelodies[0].tempo &&
        (options?.includeAllVibes?.bass || matchingVibe(l, options?.vibe))
      )
    })
    iter++
  }
  if (matchingBass.length < minimumBass) {
    return null
  }

  const minimumPercussion =
    options?.matchConfig?.percussion || PERCUSSION_MATCH_MINIMUM
  let matchingPercussion = loops.filter(l => {
    return (
      l.type == 'percussion' &&
      l.tempo == matchingMelodies[0].tempo &&
      (options?.includeAllVibes?.percussion || matchingVibe(l, options?.vibe))
    )
  })
  if (matchingPercussion.length < minimumPercussion) {
    return null
  }
  if (matchingPercussion.length > 8) {
    // yikes too many
    matchingPercussion = getRandomSubarray(matchingPercussion, 8, rng)
  }

  const minimumDrums = options?.matchConfig?.drums || DRUMS_MATCH_MINIMUM
  let matchingDrums = loops.filter(l => {
    return (
      l.type == 'drums' &&
      l.tempo == matchingMelodies[0].tempo &&
      (options?.includeAllVibes?.drums || matchingVibe(l, options?.vibe))
    )
  })
  if (matchingDrums.length < minimumDrums) {
    return null
  }
  if (matchingDrums.length > 8) {
    // yikes too many
    matchingDrums = getRandomSubarray(matchingDrums, 8, rng)
  }

  const vibeSort = (a: LoopDetail, b: LoopDetail): number => {
    if (!options || !options.vibe) return 0
    let vibeMatch = 0
    if (packToVibe[a.pack] && packToVibe[a.pack] === options.vibe)
      vibeMatch -= 1
    if (packToVibe[b.pack] && packToVibe[b.pack] === options.vibe)
      vibeMatch += 1
    return vibeMatch
  }

  return {
    melodic: shuffle(matchingMelodies, rng).sort(vibeSort),
    bass: shuffle(matchingBass, rng).sort(vibeSort),
    percussion: shuffle(matchingPercussion, rng).sort(vibeSort),
    drums: shuffle(matchingDrums, rng).sort(vibeSort),
  }
}

export function getLoopDetail(
  value: string,
  byKey: string = 'fileName'
): LoopDetail | null {
  const matches = loops.filter(loop => loop[byKey] === value)
  if (matches.length != 1) {
    console.error(
      `Tried to get loop ${value} but found ${matches.length} results`
    )
    return null
  }
  return matches[0]
}

export function getLoopById(loop_id: string): LoopDetail | null {
  const match = loopManifest[loop_id]
  if (!match) return null
  if (!isLoopDetail(match)) {
    console.error(match, 'is not loop')
    return null
  }
  return match
}

function getRandomSubarray(
  arr: Array<any>,
  size: number,
  rng: seedrandom.PRNG
) {
  const shuffled = arr.slice(0)
  let i = arr.length,
    temp,
    index
  const min = i - size
  while (i-- > min) {
    index = Math.floor((i + 1) * rng())
    temp = shuffled[index]
    shuffled[index] = shuffled[i]
    shuffled[i] = temp
  }
  return shuffled.slice(min)
}
