import React, { FC, useEffect } from 'react'
import { Image, Button, FlexContainer, Text } from '@izocloud/ui'
import { useAnalytics, events } from '@izocloud/analytics'
import { useAuth } from '@izocloud/auth'
import { VibeType } from './loops'

interface SetVibeProps {
  currentVibe?: VibeType | null
  setVibe: (vibe?: VibeType) => void
  setEngineStarted: () => void
}
const SetVibe: FC<SetVibeProps> = ({
  setVibe,
  setEngineStarted,
  currentVibe,
}) => {
  const { mixpanelEvent } = useAnalytics()
  const { user } = useAuth()
  const getClass = (title: string) => {
    if (title.toLowerCase() === currentVibe) {
      return 'selected'
    }
    return ''
  }
  const vibes = [
    {
      title: 'Electric',
      image: 'electric.svg',
      subtext: 'POP • ELECTRONIC',
      loadFunc: () => setVibe('electric'),
    },
    {
      title: 'Warm',
      image: 'warm.svg',
      subtext: 'HIP HOP • POP',
      loadFunc: () => setVibe('warm'),
    },
    {
      title: 'Gritty',
      image: 'gritty.svg',
      subtext: 'TRAP • HIP HOP',
      loadFunc: () => setVibe('gritty'),
    },
  ]
  return (
    <FlexContainer
      direction='vertical'
      id='SetVibe'
      className={user ? 'authed' : ''}
    >
      <FlexContainer primaryAlign='space-around' className='setvibe-top'>
        {vibes.map(vibe => {
          return (
            <Button
              type='text'
              key={vibe.title}
              className={'card card-active setvibe ' + getClass(vibe.title)}
              onClick={() => {
                vibe.loadFunc()
                setEngineStarted()
                if (mixpanelEvent)
                  mixpanelEvent(
                    currentVibe === undefined
                      ? events.setVibe
                      : events.changeVibe,
                    {
                      vibe: vibe.title.toLowerCase(),
                    }
                  )
              }}
            >
              <Image src={vibe.image} alt='' />
              <Text>{vibe.title}</Text>
              <Text type='small'>{vibe.subtext}</Text>
            </Button>
          )
        })}
      </FlexContainer>
      <FlexContainer className='setvibe-top'>
        <Button
          type='text'
          className={`card card-active setvibe mix-it-up ${
            currentVibe === null ? 'selected' : ''
          }`}
          onClick={() => {
            setVibe()
            setEngineStarted()
            if (mixpanelEvent)
              mixpanelEvent(
                currentVibe === undefined ? events.setVibe : events.changeVibe,
                { vibe: 'mixed' }
              )
          }}
        >
          <Text>Mix it up</Text>
        </Button>
      </FlexContainer>
    </FlexContainer>
  )
}

export default SetVibe
