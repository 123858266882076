import React, { FC, useCallback, useState } from 'react'
import {
  Button,
  FlexContainer,
  Image,
  Text,
  Spacer,
  ExternalLink,
} from '@izocloud/ui'
import { useAnalytics, events } from '@izocloud/analytics'
import { useAuth } from '@izocloud/auth'
import { popUpHotjar } from '../utils'

interface DropdownProps {
  confirm: boolean
}

const Dropdown: FC<DropdownProps> = ({ confirm }) => {
  const { user, logout } = useAuth()
  const { mixpanelEvent, hotjarEvent } = useAnalytics()
  const listRef = React.useRef<HTMLUListElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const logOut = () => {
    if (mixpanelEvent) mixpanelEvent(events.logoutButtonClick)
    if (confirm) {
      if (
        !window.confirm(
          'Are you sure you want to sign out? You will lose your current loops.'
        )
      ) {
        return
      }
    }
    logout({ returnTo: window.location.origin })
  }

  const items = [
    {
      id: 1,
      handler: () => popUpHotjar(hotjarEvent),
      icon: 'ic_feedback.svg',
      text: 'Leave feedback',
    },
    {
      id: 2,
      handler: logOut,
      icon: 'ic_signout.svg',
      text: 'Sign out',
    },
  ]

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const keyHandler = (event: React.KeyboardEvent) => {
    if (isOpen) {
      switch (event.key) {
        case 'Escape':
          setIsOpen(false)
          break
      }
    }
  }

  return (
    <div id='Dropdown-wrapper' onKeyUp={keyHandler}>
      <div
        id='Dropdown-button'
        onClick={handleClick}
        className={`${isOpen ? 'Open' : 'Closed'}`}
      >
        <FlexContainer
          direction='horizontal'
          id='Dropdown-button-content'
          className={isOpen ? 'open' : 'closed'}
        >
          {window.innerWidth < 600 ? (
            <Button type='text'>
              <Image src={user?.picture} alt='Ozone Logo' id='User-id-badge' />
            </Button>
          ) : (
            <Image src={user?.picture} alt='Ozone Logo' id='User-id-badge' />
          )}
          {/*Spacer only if dropdown is open*/}
          {isOpen ? <Spacer width={8} /> : null}
          <div id='Header-right-content' className={isOpen ? 'open' : 'closed'}>
            {isOpen ? (
              <Text type='paragraph' color='white'>
                {user?.name}
              </Text>
            ) : null}
          </div>
        </FlexContainer>
        <ul
          id='dropdown1'
          ref={listRef}
          className={isOpen ? 'open' : 'closed'}
          role='list'
        >
          <FlexContainer direction='vertical' id='modal-wrapper'>
            <hr id='dropdown-hr' className={isOpen ? 'open' : 'closed'} />
            {items.map((item, index) => (
              <Button
                type='text'
                key={index}
                className='modal-list-item'
                onClick={item.handler}
              >
                {item.text}
                <Image
                  className='Dropdown-icon'
                  src={item.icon}
                  alt={item.text}
                ></Image>
              </Button>
            ))}
          </FlexContainer>
          <FlexContainer
            id='Dropdown-tos'
            direction='horizontal'
            primaryAlign='space-around'
          >
            <Button type='text' size='small'>
              <ExternalLink to='https://www.izotope.com/en/privacy-policy.html'>
                Privacy Policy
              </ExternalLink>
            </Button>
            <Button type='text' size='small'>
              <ExternalLink to='https://www.izotope.com/en/terms-of-use.html'>
                Terms of Service
              </ExternalLink>
            </Button>
          </FlexContainer>
        </ul>
      </div>
    </div>
  )
}

export default Dropdown
