import type { NextPage } from 'next'
import { FC } from 'react'
import Head from 'next/head'
import * as Sentry from '@sentry/react'
import { Auth } from '@izocloud/auth'
import App from '../components/App'
import HeaderLeft from '../components/HeaderLeft'
import {
  Header,
  Crash,
  Footer,
  Button,
  FlexContainer,
  Loading,
} from '@izocloud/ui'
import { Analytics } from '@izocloud/analytics'
import { AuthCustomLogin } from '../components/AuthCustomLogin'
import {
  AUTH0_CONFIG,
  MIXPANEL_TOKEN,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  HOTJAR_TRACKING_ID,
} from '../utils'

const appFallback = (
  <FlexContainer id='App-fallback' direction='vertical'>
    <Header left={<HeaderLeft />} />
    <Crash>
      <Button onClick={() => window.location.reload()}>Try Again</Button>
    </Crash>
    <Footer />
  </FlexContainer>
)

const CustomError: FC = () => appFallback
const CustomLoading: FC = () => (
  <div id='AuthCustomLoading'>
    <Loading />
    <Footer />
  </div>
)

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Trackstarter</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <meta name='description' content='trackstarter' />
        <meta property='og:image' content='/trackstarter_opengraph.png' />
        <meta property='og:title' content='Made with Trackstarter' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='Trackstarter | iZotope Labs' />
      </Head>
      <Sentry.ErrorBoundary fallback={appFallback}>
        <Analytics
          MIXPANEL_TOKEN={MIXPANEL_TOKEN}
          SENTRY_DSN={SENTRY_DSN}
          SENTRY_ENVIRONMENT={SENTRY_ENVIRONMENT}
          HOTJAR_TRACKING_ID={HOTJAR_TRACKING_ID}
          defaultMetadata={{}}
        >
          <Auth
            AUTH0_CONFIG={AUTH0_CONFIG}
            CustomLogin={AuthCustomLogin}
            CustomError={CustomError}
            CustomLoading={CustomLoading}
            loginRequired={false}
          >
            <App />
          </Auth>
        </Analytics>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default Home
