import { FC } from 'react'

interface LoopCardProps {
  type: string
  inhibitClicks: boolean
  currentMute: boolean
  setMute: (cardType: string, mute: boolean) => void
  active: boolean
  makeActive: () => void
}

const LoopCard: FC<LoopCardProps> = ({
  type,
  currentMute,
  setMute,
  inhibitClicks,
  active,
  makeActive,
}) => {
  let className = `card card-${type}`
  if (active) {
    className += ' card-active'
  }
  if (currentMute) {
    className += ' card-mute'
  }

  const onClick = () => {
    if (!inhibitClicks) {
      if (active) {
        setMute(type, !currentMute)
      } else {
        makeActive()
      }
    }
  }

  return (
    <img
      className={className}
      draggable='false'
      src={`ic_instrument_${type}.svg`}
      alt=''
      onClick={onClick}
    />
  )
}

export default LoopCard
