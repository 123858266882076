import { FC } from 'react'
import { Image, Button } from '@izocloud/ui'
import { useAnalytics } from '@izocloud/analytics'
import { popUpHotjar } from '../utils'

const HeaderLeft: FC = () => {
  const { hotjarEvent } = useAnalytics()
  return (
    <Button
      id='Header-left'
      type='text'
      onClick={() => {
        if (hotjarEvent) popUpHotjar(hotjarEvent)
      }}
    >
      <Image src='feedback.svg' alt='logo' id='feedback' />
    </Button>
  )
}

export default HeaderLeft
