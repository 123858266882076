import { FC } from 'react'
import { Button, Text } from '@izocloud/ui'
import { VibeType } from './loops'

interface HeaderCenterProps {
  engineStarted: boolean
  shouldRenderSetVibe: boolean
  musicalKey: string
  tempo: number
  vibe: VibeType
  isSharedPage: boolean
  setShouldRenderSetVibe: (boolean) => void
}

const HeaderCenter: FC<HeaderCenterProps> = ({
  engineStarted,
  shouldRenderSetVibe,
  setShouldRenderSetVibe,
  musicalKey,
  tempo,
  vibe,
  isSharedPage,
}) => {
  let vibeStr: string = vibe
  if (!vibeStr) vibeStr = 'mixed'
  if (engineStarted && musicalKey && tempo && !isSharedPage) {
    return (
      <Button
        type='solid'
        onClick={() => {
          setShouldRenderSetVibe(!shouldRenderSetVibe)
        }}
      >
        {shouldRenderSetVibe
          ? 'Cancel'
          : `${
              vibeStr.charAt(0).toUpperCase() + vibeStr.slice(1)
            } - ${tempo} ${musicalKey}`}
      </Button>
    )
  } else {
    return <Text>Trackstarter</Text>
  }
}

export default HeaderCenter
