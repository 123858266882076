import * as path from 'path'

export function formatTime(seconds: number) {
  if (seconds === null) return '-:--'
  const mins = Math.floor(seconds / 60)
  seconds = Math.floor(seconds % 60)
  const strSeconds = seconds < 10 ? '0' + seconds : String(seconds)
  return String(mins) + ':' + strSeconds
}

export function isInternalUser(email: string) {
  let re = /@(izotope\.com|native-instruments\.de|native-instruments\.com)$/i
  return re.test(email)
}

export function getOutputName(inputName: string) {
  return `${path.basename(inputName).replace(/\.[^/.]+$/, '')}_repaired.wav`
}
